<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization" vid="org_id"   rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('seedsConfig.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="zoneOfficeData.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Office Name" vid="spc_office_name" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="spc_office_name"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('seedsConfig.ofcName')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="spc_office_name"
                            v-model="zoneOfficeData.spc_office_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Office Name (Bn)" vid="spc_office_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="spc_office_name_bn"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('seedsConfig.ofcNameBN')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="spc_office_name_bn"
                            v-model="zoneOfficeData.spc_office_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                         <ValidationProvider name="Division" vid="pre_division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="pre_division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('org_pro_division.division')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="zoneOfficeData.division_id"
                                :options="divisionPreList"
                                id="pre_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="District" vid="pre_district_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="pre_district_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('org_pro_district.district')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="zoneOfficeData.district_id"
                                  :options="districtList"
                                  id="pre_district_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                           <ValidationProvider name="Upazilla" vid="pre_upazilla_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="pre_upazilla_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="zoneOfficeData.upazilla_id"
                                    :options="upazilaList"
                                    id="pre_upazilla_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Address" vid="address">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="address"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('seedsConfig.addr')}}
                          </template>
                          <b-form-input
                            id="address"
                            v-model="zoneOfficeData.address"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Address (Bn)" vid="address_bn">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="address_bn"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('seedsConfig.addrBn')}}
                          </template>
                          <b-form-input
                            id="address_bn"
                            v-model="zoneOfficeData.address_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Email" vid="email">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="email"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('seedsConfig.email')}}
                          </template>
                          <b-form-input
                           type="email"
                            id="email"
                            v-model="zoneOfficeData.email"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Contact No." vid="contact_no" rules="digits:11|required">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="contact_no"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('seedsConfig.contact')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="contact_no"
                            type="number"
                            v-model="zoneOfficeData.contact_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { spcOfficeStore, spcOfficeUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getSPCOfcData()
      this.zoneOfficeData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      zoneOfficeData: {
        id: '',
        org_id: 0,
        spc_office_name: '',
        spc_office_name_bn: '',
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        address: '',
        address_bn: '',
        email: '',
        contact_no: '',
        status: '1'
      },
      districtList: [],
      upazilaList: []
    }
  },
   computed: {
    orgList: function () {
      const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return tmpData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    },
    divisionPreList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    yearOfRegistration: function () {
      const fiscalyear = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyear.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    }
  },
  watch: {
    'zoneOfficeData.division_id': function (newVal, oldVal) {
      this.districtList = this.getPreDistrictList(newVal)
    },
    'zoneOfficeData.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getPreUpazilaList(newVal)
    }
  },
  methods: {
    getSPCOfcData () {
       const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.zoneOfficeData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${spcOfficeUpdate}/${this.id}`, this.zoneOfficeData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, spcOfficeStore, this.zoneOfficeData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getPreDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getPreUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    }
  }
}
</script>
